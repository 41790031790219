import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Home from '../Page/Home';
import Final from '../Page/Final';
import Paymentfail from '../Page/Paymentfail';

/**
 *  React component that renders the router of the application
 * This component is used in the Layout component
 */
const RouterApp = () => {
  return (
    <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<Navigate to="/form" />} />
          
          <Route path="/form" element={<Home />} />
          <Route path="/final" element={<Final />} />
          <Route path="/paymentfail" element={<Paymentfail />} />

          <Route path="*" element={<h1>404</h1>} />

        </Routes>
    </BrowserRouter>
  );
};

export default RouterApp;