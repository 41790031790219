import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './App/layout/Layout';


function App() {
  return (
    <>
        <Layout />
    </>
  );
}

export default App;
