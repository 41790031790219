import { Navbar, Container } from 'react-bootstrap'
import Logo from '../../Images/Fitness Leaf Ai - rectangle logo - 400x100.png'

const Header = () => {
    return (
        <Navbar className="bg-body-dark bg-dark" variant="dark">
        <Container>
          <Navbar.Brand href="https://fitnessleafai.com">
            <img
              src={Logo}
              height="45"
              className="d-inline-block align-top"
              alt="Fitness Leaf AI logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    )
}

export default Header