const Footer = () => {

    const currentYear = new Date().getFullYear(); 

    return (
        <footer className="footer mt-auto">
            <div className="text-center p-4 bg-dark text-white">
            Copyright © {currentYear} | Fitness Leaf AI | 93 Labs Pty Ltd<br/>
                {/* Powered by
                 <a className="text-reset fw-bold" href="https://lakinduw.me" style={{textDecoration: "none"}}> LWA Technologies</a> */}
            </div>
        </footer>
    )
}

export default Footer