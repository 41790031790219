import Footer from "./Footer";
import Header from "./Header";
import RouterApp from "../Router/RouterApp";

  /**
   *React component that renders the layout of the application 
   */

const Layout = () => {
  return(
    <div className="d-flex flex-column min-vh-100">
      <Header />
        <div className="container">
          <RouterApp />
        </div>
      <Footer />
    </div>
  )
}

export default Layout;