import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { backendURL } from '../../config'

const MealPlanner = () => {

    // Declare states
    const [name, setName] = useState<string>('')
    const [typeOfVegetarian, setTypeOfVegetarian] = useState<string>('')
    const [cuisine, setCuisine] = useState<string>('')
    const [avoidIngredients, setAvoidIngredients] = useState<string>('')
    const [metric, setMetric] = useState<string>('')
    const [protein, setProtein] = useState<string>('')
    const [carbohydrates, setCarbohydrates] = useState<string>('')
    const [fat, setFat] = useState<string>('')
    const [totalRecipes, setTotalRecipes] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [recipespermeal, setRecipespermeal] = useState<string>('')
    const [maxTokens, setMaxTokens] = useState<number>(0)
    const [prompt, setPrompt] = useState<string>('')
    const [calories, setCalories] = useState<string>('')

    

        // Calculate the total price and recipes per meal based on the total recipes
        useEffect(() => {
            let newTotalPrice = 0;
            let newRecipesPerMeal = "";
            let newMaxTokens = 0;
        
            if (totalRecipes === 3) {
                newTotalPrice = 1;
                newRecipesPerMeal = " 1 recipe per breakfast, 1 recipe per lunch, and 1 recipe per dinner";
                newMaxTokens = 2500;
            } else if (totalRecipes === 15) {
                newTotalPrice = 5;
                newRecipesPerMeal = " 5 recipes per breakfast, 5 recipes per lunch, and 5 recipes per dinner";
                newMaxTokens = 4000;
            }
            
            setTotalPrice(newTotalPrice);
            setRecipespermeal(newRecipesPerMeal);
            setMaxTokens(newMaxTokens);
        }, [totalRecipes]);


        // Generate the prompt for the user's inputs
        useEffect(() => {
            const promptTemplate = `
            Generate ${recipespermeal} ${cuisine} meal recipes suitable for ${typeOfVegetarian} diet.
            Each meal should meet the following daily limits: 
            - Protein: ${protein}g
            - Carbohydrates: ${carbohydrates}g
            - Calories: ${calories}${metric}
            - Fat: ${fat}g
            Avoid including ${avoidIngredients} in any recipe.
            Please provide ingredients, preparation instructions, and nutrient information for each recipe.`;         
            setPrompt(promptTemplate);
        }, [typeOfVegetarian, cuisine, avoidIngredients, metric, protein, carbohydrates, fat, recipespermeal, calories]);
        

        // Submit the form
        const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            // add prompt, maxTokens, receiveMethod, totalPrice, and name to the url
            localStorage.setItem('prompt', prompt);
            localStorage.setItem('maxTokens', maxTokens.toString());
            localStorage.setItem('totalPrice', totalPrice.toString());
            localStorage.setItem('Name', name);
            localStorage.setItem('totalRecipes', totalRecipes.toString());

            // add cousine details to the url
            localStorage.setItem('cuisine', cuisine); 

            let paymentID = ''; // payment ID for stripe

            // history.push('/')

            if (totalPrice === 0) {
                window.location.href = `/final`;
            }else if (totalPrice > 0) {
                
                // post to backend to get the payment link
                // set payment ID according to the total price
                if(totalPrice === 1){
                    paymentID = 'price_1OgO6NI0SHVGI2M5DQ8varr0';
                }else if(totalPrice === 5){
                    paymentID = 'price_1OgO7aI0SHVGI2M5P8tqSPH5';
                } else {
                    paymentID = '';
                }

                // post to backend to get the payment link
                // const res = await axios.post(`${backendURL}/create-checkout-session`, { paymentID });
                try {
                    const res = await fetch(`${backendURL}/create-checkout-session`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ paymentID })
                    });
                
                    // Check if the response status is OK (200)
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                
                    // redirect to the payment link
                    const data = await res.json();
                    window.location.href = data.url;
                
                    // Continue with your logic here
                } catch (error) {
                    console.error('Error creating Checkout Session:', error);
                }

            }
        }     



    return (
        <div className="container mt-4 mb-4">
            
            {/* Form to get user's inputs*/}
            <Form className="border border-dark rounded p-3 mt-3 w-90 mx-auto box" method='POST' onSubmit={handleSubmit}>
                {/* your name and country*/}
                <h4 className="mt-4 text-success">About You<span className="text-danger">*</span></h4>
                <div className="row mt-2">
                    {/* name */}
                    <div className="col-md-6 mt-1">
                        <input type="text" className="form-control border-dark" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </div>
                    {/* dropdown menu  to */}
                </div>

                <hr /> {/* horizontal line */}


                {/* What type of vegetarian are you? */}
                <h4 className="mt-4 text-success">What type of vegetarian are you?<span className="text-danger">*</span></h4>
            
                <div className="row mt-2">
                    <div className="col-md-6 mt-1 p-2 m-1 mb-2">
                        {/* dropdown menu  to select the type of vegetarian */}
                        <select className="form-select border-dark" aria-label="Default select example" value={typeOfVegetarian} onChange={(e) => setTypeOfVegetarian(e.target.value)} required>
                            <option selected>Select</option>
                            <option value="Lacto-Vegetarian (dairy allowed, honey allowed, no eggs, no meat)">Lacto-Vegetarian</option>
                            <option value="Vegan (No dairy, No honey, No eggs, No meat)">Vegan</option>
                            <option value="Standard Vegetarian (dairy and honey allowed, no eggs, no meat)">Standard Vegetarian</option>
                        </select>
    

                        {/* What cuisine would you like to cover */}
                        <label className="mt-4 text-success">What cuisine would you like to cover?<span className="text-danger">*</span></label>
                        <input type="text" className="form-control border-dark" placeholder="Use comma to separate more than 1 choice" value={cuisine} onChange={(e) => setCuisine(e.target.value)} required/>


                        {/* What ingredients would you like to avoid?*/}
                        <label className="mt-4 text-success">What ingredients would you like to avoid?</label>
                        <input type="text" className="form-control border-dark" placeholder="Use comma to separate more than 1 choice" value={avoidIngredients} onChange={(e) => setAvoidIngredients(e.target.value)}/>
      
                    </div>

                        <div className="col-md-4 p-1 text-center  content-jusify-center d-flexs mx-auto">
                        {/* Table to show the selected type of vegetarian and their discription */}
                            <table className="table table-bordered border-dark table-hover table-responsive rounded w-100 mx-auto">
                                <thead className="bg-success text-white rounded">
                                    <tr className="text-center bg-success text-success">
                                        <th scope="col">Type of Vegetarian</th>
                                        <th scope="col">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Lacto-Vegetarian</td>
                                        <td>Dairy allowed, honey allowed, no eggs, no meat</td>
                                    </tr>
                                    <tr>
                                        <td>Vegan</td>
                                        <td>No dairy, No honey, No eggs, No meat</td>
                                    </tr>
                                    <tr>
                                        <td>Standard Vegetarian</td>
                                        <td>Dairy and honey allowed, no eggs, no meat</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
                <hr /> {/* horizontal line */}
    
                {/* Calorie count */}
                <h4 className="mt-4 text-success">Energy Value</h4>
                <div className="row mt-2">
                    {/* dropdown menu  to select the What metric | KJ or KCal */}
                    <div className="col-md-6">
                        <select className="form-select border-dark" aria-label="Default select example" value={metric} onChange={(e) => setMetric(e.target.value)} required>
                            <option selected>Choose your Calorie metric type</option>
                            <option value="KJ">KJ</option>
                            <option value="KCal">KCal</option>
                        </select>
                    </div>
                    {/* Enter your caleries limits per day (in grams) */}
                    <div className="col-md-5">
                        <input type="text" className="form-control border-dark" placeholder="Enter total calorie limit for the day" onChange={(e) => setCalories(e.target.value)} value={calories} required/>
                    </div>
                </div>


                    {/* Enter your nutrient limits per day (in grams) */}
                    <label className="mt-3 text-success">Enter Macronutrient Limits (in grams) <span className="text-danger">*</span></label>
                    <div className="col-md-5 mt-2 mb-2">
                        <input type="text" className="form-control mt-2 border-dark" placeholder="Protein (g)" onChange={(e) => setProtein(e.target.value)} value={protein} required/>
                        <input type="text" className="form-control mt-2 border-dark" placeholder="Carbohydrates (g)" onChange={(e) => setCarbohydrates(e.target.value)} value={carbohydrates} required/>
                        <input type="text" className="form-control mt-2 border-dark" placeholder="Fat (g)" onChange={(e) => setFat(e.target.value)} value={fat} required/>
                    </div>
                
                <hr /> {/* horizontal line */}

                {/* Prices and quantity */}
                <h4 className="mt-3 text-success text-center">Price<span className="text-danger">*</span></h4>

                {/* Add 4 column to add price details */}
                <div className="row mt-2 d-flex justify-content-center">
        
                    {/* column 2 */}
                    <div className="col-md-2 m-3 text-center border border-dark rounded p-3 box">
                        <h4 className="mt-1 text-dark"> <b>3</b> recipes</h4>
                        <h5 className="mt-1 text-success">1 recipes per 3 meals</h5>
                        <h5 className="mt-1 text-danger"><b>A$1</b></h5>
                        <input className="form-check-input border-dark" type="radio" name="flexRadioDefault" value='1' onClick={() => setTotalRecipes(3)}/> 
                    </div>
                    {/* column 3 */}
                    <div className="col-md-2 m-3 text-center border border-dark rounded p-3 box">
                        <h4 className="mt-1 text-dark"> <b>15</b> recipes</h4>
                        <h5 className="mt-1 text-success">5 recipes per 3 meals</h5>
                        <h5 className="mt-1 text-danger"><b>A$5</b></h5>
                        <input className="form-check-input border-dark" type="radio" name="flexRadioDefault" value='15' onClick={() => setTotalRecipes(15)}/>
                    </div>
    
                </div>

                {/* Button to show the total price */}
                <div className=" text-center text-success content-jusify-center d-flexs">
                    <h3 className="p-auto mt-4 col-md-4 text-center rounded w-100">Total Price:<b> ${totalPrice}</b></h3>
                </div>

                
                <hr className='p-2'/> {/* horizontal line */}
                    
                <h5 className="mt-4 text-success font-weight-bold"
                >DISCLAIMER <span className="text-danger">*</span></h5>
                    
                    
                {/* Add checkbox to accept terms and conditions */}
                <div className="form-check mt-2">
                    <input className="form-check-input border-dark" type="checkbox" id="flexCheckDefault" required/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    Recognizing AI's potential for errors as a work-in-progress tool, I exempt Fitness Leaf AI and its partners from accountability for any processing or output mistakes.
                    </label>
                </div>

                                {/* Add checkbox to accept terms and conditions */}
                <div className="form-check mt-2">
                    <input className="form-check-input border-dark mt-2" type="checkbox" id="flexCheckDefault" required/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                     While Fitness Leaf AI strives to provide personalized and tailored recipes, it’s important to note that any nutritional advice or guidance offered on this platform is for informational purposes only. We are not liable for the accuracy, completeness, or suitability of the information provided in our recipes. Users are strongly encouraged to seek advice from qualified nutritionists, registered dieticians, or healthcare professionals regarding their dietary needs and health concerns.
                    </label>
                </div>

                {/* Make Payment button */}

                <div className=" text-center content-jusify-center d-flexs">
                        {/* Make Payment button */}
                        {totalPrice > 0 ? (
                            <button type="submit" className="btn btn-dark mb-4 p-auto mt-4 col-md-4 text-center" >
                                Make Payment
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-dark mb-4 p-auto mt-4 col-md-4 text-center disabled" >
                                Make Payment
                            </button>
                        )}
                </div>




 


                {/* <div className="container text-center mt-4" id='results'>
                    {isLoading ? (<Loading />) : (<div>

                    </div>)}
                </div>  */}

            </Form>
        </div>
    )
}

export default MealPlanner