const Paymentfail = () => {
    return (
        <div className="container text-center">

                <div className="col-md-12 text-center mt-5">
                    <h1 className="text-danger">Payment Failed</h1>
                </div>

                <div className="col-md-12 text-center mt-5">
                    <h4 className="text-dark">Please try again</h4>

                    <a href="/home" className="btn btn-success btn-lg mt-3 mb-5"
                    >Go Back</a>
                </div>   
        </div>
    );
    }

export default Paymentfail;