import { Form } from 'react-bootstrap';
import { useState } from 'react';
import jsPDF from 'jspdf';
import axios from 'axios';
import { backendURL } from '../../config';
import 'jspdf-autotable';



const Final = () => {

  // Get data from local storage
  const name = localStorage.getItem('Name');
  const totalRecipes = localStorage.getItem('totalRecipes');
  const totalPrice = localStorage.getItem('totalPrice');
  const cuisine = localStorage.getItem('cuisine');

  // Set state for receive method
  const [loading, setLoading] = useState(false);
  const [finallySubmitted, setFinallySubmitted] = useState(false);

    /**
     * Generates recipes based on the prompt and maximum number of tokens.
     * @returns The response data containing the generated recipes.
     */
    async function generateRecipes() {
      const prompt = localStorage.getItem('prompt');
      const max_tokens = localStorage.getItem('maxTokens');


      const maxTokens = parseInt(max_tokens!); // convert string to number

      // make loading true
      setLoading(true);

      try {
        // Make the POST request
        const response = await axios.post(`${backendURL}/generateRecipes`, {
          prompt: prompt,
          max_tokens: maxTokens,
        });
        const mealplanText = response.data;
        const mealplan = mealplanText.split('\n\n');

        // Generate PDF only if recipes are available
        if (mealplan.length > 0) {
          generatePDF(mealplan);
        }

      } catch (error) {
        console.error('Error while Generating Recipes:', error);
        return null; // or throw an error if needed
      } finally {
        // make loading false
        setLoading(false);
        setFinallySubmitted(true);

        // clear local storage
        localStorage.clear();
      }
    }

    /**
     * Generates a PDF document with the provided recipes.
     * @param recipes An array of strings representing the recipes.
     * @returns A Promise that resolves when the PDF is generated.
     */
    async function generatePDF(recipes: string[]) {
      const doc = new jsPDF({ orientation: 'portrait', format: 'a4' });

      const logo = require('../../Images/Fitness Leaf Ai - rectangle logo - 400x100.png');
      doc.addImage(logo, 'PNG', 80, 10, 50, 20);


      const welcomeMessage = `
        Hello ${name},

        Thank you for choosing Fitness Leaf AI for your recipes.
        We hope you like what we have pulled for you,
        and you will cook them to your heart’s content.
        If you have any issues with this output,
        please contact us at <https://tally.so/r/w8QeEk>.
        If you like what you got, please talk about us to your friends and family!
        Bon appétit!

        Your meal plan is ready.
        Your cuisine type is ${cuisine}.
        You will receive ${totalRecipes} recipes.
        Your total price is $${totalPrice}.

        Regards,
        Fitness Leaf AI team
      `;

      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);
      doc.text(welcomeMessage, 12, 40);

      doc.addPage();
      doc.setFontSize(20);
      doc.setTextColor(0, 0, 0);
      doc.text('Your Recipes', 85, 20);
      doc.setFontSize(15);
      doc.setTextColor(0, 0, 0);

      const tableHeaders = ['Your Recipes'];
      const tableData = recipes.map((recipe) => [recipe]);

      (doc as any).autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 30,
        theme: 'grid',
        styles: {
          fontSize: 12,
          cellPadding: 2,
          overflow: 'linebreak',
          halign: 'left'
        },
      });
;
        doc.save('Fitness Leaf AI.pdf');
    };





  // Onsubmit function
  const onSubmit = (e: any) => {
    e.preventDefault();
    // call generateRecipes function
        generateRecipes();
  };



  return (
    <div>
      <div className="div text-center p-4 text-success mt-2">

      
        
        <Form  className="border border-dark rounded p-3 mt-3 mb-5 w-90 mx-auto box" method="POST" onSubmit={onSubmit} >

          <h4 className='pt-5 pb-1'>
            Hello {name}! You're all set to go. <br />
            We're preparing your meal plan.
          </h4>
          <p className="text-dark pb-3">You will receive {totalRecipes} recipes.</p>

            {/* Add loading spinner */}
            {loading && (
            <div className="text-center mt-3 mb-5 p-3">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
 

          {/* Add submit button */}
          <div className="text-center mt-3 mb-5">
            <button type="submit" className="btn btn-dark mb-4 p-auto mt-4 col-md-4 text-center">
              Download
            </button>
          </div>



        </Form>

      </div>

      {/* Show Final message  for user */}
      {finallySubmitted && (
        <div className="text-center p-4 text-success mt-2">
          <h4>
            Thank you for choosing Fitness Leaf AI for your recipes.
            We hope you like what we have pulled for you,
            and you will cook them to your heart’s content.
            If you have any issues with this output,
            please contact us at <a href="https://tally.so/r/w8QeEk">this</a>.
            If you like what you got, please talk about us to your friends and family!
          </h4>
        </div>
      )}
    </div>
  );


};

export default Final;
